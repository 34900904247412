// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-crazy-jack-contact-js": () => import("./../../../src/pages/crazy-jack/contact.js" /* webpackChunkName: "component---src-pages-crazy-jack-contact-js" */),
  "component---src-pages-crazy-jack-index-js": () => import("./../../../src/pages/crazy-jack/index.js" /* webpackChunkName: "component---src-pages-crazy-jack-index-js" */),
  "component---src-pages-crazy-jack-work-js": () => import("./../../../src/pages/crazy-jack/work.js" /* webpackChunkName: "component---src-pages-crazy-jack-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nature-careers-js": () => import("./../../../src/pages/nature/careers.js" /* webpackChunkName: "component---src-pages-nature-careers-js" */),
  "component---src-pages-nature-case-studies-energy-js": () => import("./../../../src/pages/nature/case-studies/energy.js" /* webpackChunkName: "component---src-pages-nature-case-studies-energy-js" */),
  "component---src-pages-nature-case-studies-index-js": () => import("./../../../src/pages/nature/case-studies/index.js" /* webpackChunkName: "component---src-pages-nature-case-studies-index-js" */),
  "component---src-pages-nature-case-studies-koalaqa-js": () => import("./../../../src/pages/nature/case-studies/koalaqa.js" /* webpackChunkName: "component---src-pages-nature-case-studies-koalaqa-js" */),
  "component---src-pages-nature-case-studies-oilmanagement-js": () => import("./../../../src/pages/nature/case-studies/oilmanagement.js" /* webpackChunkName: "component---src-pages-nature-case-studies-oilmanagement-js" */),
  "component---src-pages-nature-case-studies-swiftsole-js": () => import("./../../../src/pages/nature/case-studies/swiftsole.js" /* webpackChunkName: "component---src-pages-nature-case-studies-swiftsole-js" */),
  "component---src-pages-nature-case-studies-triprite-js": () => import("./../../../src/pages/nature/case-studies/triprite.js" /* webpackChunkName: "component---src-pages-nature-case-studies-triprite-js" */),
  "component---src-pages-nature-contact-js": () => import("./../../../src/pages/nature/contact.js" /* webpackChunkName: "component---src-pages-nature-contact-js" */),
  "component---src-pages-nature-index-js": () => import("./../../../src/pages/nature/index.js" /* webpackChunkName: "component---src-pages-nature-index-js" */),
  "component---src-pages-nature-loading-js": () => import("./../../../src/pages/nature/loading.js" /* webpackChunkName: "component---src-pages-nature-loading-js" */),
  "component---src-pages-nature-thank-you-js": () => import("./../../../src/pages/nature/thank-you.js" /* webpackChunkName: "component---src-pages-nature-thank-you-js" */)
}

